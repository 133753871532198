import React from 'react';
import { motion } from 'framer-motion';

const RegistrationOptions = ({ onContact }) => (
  <div className="flex flex-col sm:flex-row justify-center space-y-0 sm:space-y-0 sm:space-x-0 mb-8">
  <motion.button
    onClick={onContact}
    className="bg-gold text-white px-8 py-3 rounded-md shadow-md hover:shadow-lg font-semibold transition-all duration-300"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    Want to join? Contact us!
  </motion.button>
  </div>
);

export default RegistrationOptions;