import React, { useState, lazy, Suspense } from 'react';
import { motion } from 'framer-motion';

// Import components
import Header from './Header';
import Footer from './Footer';
import RegistrationOptions from './RegistrationOptions';
import ImageCarousel from './ImageCarousel';

// Lazy load components
const InstructorInfo = lazy(() => import('./InstructorInfo'));
const LocationMap = lazy(() => 
  import('./LocationMap').then(module => ({
    default: props => <Suspense fallback={<div>Loading map...</div>}><module.default {...props} /></Suspense>
  }))
);
const ContactModal = lazy(() => import('./ContactModal'));

const LandingPage = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const carouselImages = [
    '/images/carousel/dance3.jpg',  // Group line-up under sign
    '/images/carousel/dance4.jpg',  // Three students together
    '/images/carousel/dance1.jpg',  // Certificate achievement
    '/images/carousel/dance2.jpg',  // Adult class (earlier one)
    '/images/carousel/dance5.jpg'   // Adult class (later one)
  ];

  return (
    <div className="flex flex-col min-h-screen text-black bg-white">
      <Header />
      
      <main className="relative z-10 flex-grow">
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center overflow-hidden">
        {/* Background with refined handling */}
        <div 
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: 'url("/images/studio-background.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center 40%',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // This adds a white overlay
            backgroundBlendMode: 'lighten' // This blends the background color with the image
          }}
        >
          {/* Multi-layer gradient for depth */}
          <div className="absolute inset-0 bg-gradient-to-b from-white/98 via-white/95 to-white/90" />
          <div className="absolute inset-0 bg-white/30 backdrop-blur-[2px]" />
        </div>

        {/* Hero Content Container */}
        <div className="relative z-10 w-full mb-10">
          <div className="container mx-auto px-6">
            <motion.div 
              className="max-w-4xl mx-auto"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: { 
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.3
                  }
                }
              }}
            >
              {/* Logo with refined animation */}
              <motion.div 
                className="flex justify-center mb-16"
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 }
                }}
              >
                <div className="relative">
                  <motion.img 
                    src="/images/galway-school-logo.png"
                    alt="Galway School of Irish Dancing"
                    className="w-36 h-36 sm:w-48 sm:h-48 rounded-full border-4 border-gold/80 shadow-xl hover:shadow-2xl transition-all duration-500"
                    animate={{ 
                      y: [0, -6, 0],
                    }}
                    transition={{
                      y: {
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }
                    }}
                    loading="lazy"
                  />
                  <div className="absolute -inset-1 bg-gold/20 rounded-full blur-md -z-10" />
                </div>
              </motion.div>

              {/* Text Content */}
              <div className="text-center space-y-12">
                {/* Tagline */}
                <motion.p 
                className="text-xl sm:text-2xl lg:text-3xl font-serif italic text-black font-large max-w-2xl mx-auto leading-relaxed" // Removed opacity and added font-medium
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 }
                }}
              >
                Step into tradition, dance into the future.
              </motion.p>

                {/* CTA Button */}
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0 }
                  }}
                >
                  <RegistrationOptions 
                    onContact={() => setShowContactModal(true)}
                  />
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Decorative bottom gradient */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white via-white/50 to-transparent" />
      </section>

        {/* Showcase Section with soft gradient background */}
        <section className="w-full py-20 md:py-24 bg-gradient-to-b from-white via-white to-gray-50">
          <div className="w-full px-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <div className="max-w-2xl mx-auto text-center mb-12 md:mb-16">
                <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 text-gold">
                  Our Dance Community
                </h2>
              </div>
              <ImageCarousel images={carouselImages} />
            </motion.div>
          </div>
        </section>

        {/* Info Section with refined background */}
        <section className="py-4 md:py-4 bg-gradient-to-b from-gray-50 to-white">
          <div className="container mx-auto px-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="max-w-6xl mx-auto"
            >
              <div className="max-w-2xl mx-auto text-center mb-12 md:mb-16">
                <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 text-gold">
                  Learn More About Us
                </h2>
                <p className="text-black/80 text-lg">
                  Meet our experienced instructor and discover our convenient location.
                </p>
              </div>
              <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
                <Suspense fallback={
                  <div className="h-64 bg-white rounded-lg animate-pulse shadow-md" />
                }>
                  <motion.div
                    whileHover={{ y: -4 }}
                    transition={{ duration: 0.2 }}
                    className="bg-white shadow-md hover:shadow-lg rounded-lg transition-all duration-300"
                  >
                    <InstructorInfo />
                  </motion.div>
                </Suspense>
                <Suspense fallback={
                  <div className="h-64 bg-white rounded-lg animate-pulse shadow-md" />
                }>
                  <motion.div
                    whileHover={{ y: -4 }}
                    transition={{ duration: 0.2 }}
                    className="bg-white shadow-md hover:shadow-lg rounded-lg transition-all duration-300"
                  >
                    <LocationMap />
                  </motion.div>
                </Suspense>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section className="py-16 bg-gradient-to-b from-white to-gold/5">
          <div className="container mx-auto px-6">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="max-w-3xl mx-auto text-center"
            >
              <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-gold">
                Ready to Begin Your Irish Dance Journey?
              </h2>
              <motion.button
                onClick={() => setShowContactModal(true)}
                className="bg-gold text-white px-8 py-3 rounded-md shadow-md hover:shadow-lg font-semibold transition-all duration-300"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Contact Us Today
              </motion.button>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />

      <Suspense fallback={<div>Loading...</div>}>
        {showContactModal && (
          <ContactModal
            isOpen={showContactModal}
            onClose={() => setShowContactModal(false)}
          />
        )}
      </Suspense>
    </div>
  );
};

export default LandingPage;