// Header.js
import React from 'react';

const Header = () => (
  <header className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-md shadow-sm transition-all duration-300">
    <div className="container mx-auto px-6">
      <div className="flex justify-between items-center h-16 md:h-20">
        <div>
          <img 
            src="/images/galway-irish-dance-landing.png" 
            alt="Galway School of Irish Dancing" 
            className="h-12 md:h-14 w-auto object-contain transition-transform duration-300 hover:scale-105"
          />
        </div>
        <nav className="hidden md:flex space-x-8">
          {/* Future nav items */}
        </nav>
      </div>
    </div>
  </header>
);

export default Header;