// Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const Footer = () => (
  <footer className="relative z-10 bg-white py-6 border-t border-gray-100">
    <div className="container mx-auto px-6">
      <div className="flex flex-col items-center space-y-4">
        <div className="flex justify-center space-x-6">
          <a 
            href="https://www.facebook.com/profile.php?id=61563335731693" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-gold hover:text-light-gold transition-all duration-300 hover:-translate-y-1"
          >
            <FaFacebookF size={22} />
          </a>
          <a 
            href="https://www.instagram.com/galwayschoolofirishdance/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-gold hover:text-light-gold transition-all duration-300 hover:-translate-y-1"
          >
            <FaInstagram size={22} />
          </a>
        </div>
        <p className="text-sm text-slate-blue/70 font-serif italic">
          © {new Date().getFullYear()} Galway School of Irish Dancing
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;